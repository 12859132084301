// Define color variables (assuming values from your 'styles/constant' file)
$modal-border-color: black; // Replace with actual color
$black-color: #000000;
$loader-span-height: 25px;

// Draggable Modal
.draggable-modal {
    // Header
    .header {
        padding: 0 0 5px 0px;
        border-bottom: 1px solid rgb(221, 221, 221);
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    // Title
    .title {
        font-size: 16px;
        font-weight: 700;
        color: $black-color;
        display: flex;
        align-items: center;
    }

    // Loader Span
    .loader-span {
        display: inline-flex;
        align-items: center;
        margin-right: 15px;
        height: $loader-span-height;
    }

    .icons {
        display: flex;
        align-items: center;
    }

    .clear-icon {
        margin-right: 0px;
        margin-left: 5px;
        cursor: pointer;
    }

    .close-icon {
        cursor: pointer;
    }

    transform: translate(-50%, -50%);
    textarea {
        height: 140px;
        resize: none;
        line-height: 1.5;
        width: 100%;
        border: 1px solid #f5f5f5;
        border-radius: 6px;
        background-color: #ffffff;
        padding: 5px 10px;
        font-size: 14px;
        color: $black-color;
        transition: 0.3s;
    }
}
