$modal-bg-color: #f5f5f5;
$modal-border-color: #bbbbbb;
$export-forms-bg-color: white;
$export-forms-border-color: #dddddd;
$border-radius: 5px;

.export-search-modal-content {
  padding: 15px;
  background-color: $modal-bg-color;
  margin-bottom: 20px;
  border: 1px solid $modal-border-color;
  border-radius: $border-radius;

  .export-forms {
    padding: 10px;
    margin-top: 10px;
    background-color: $export-forms-bg-color;
    width: 100%;
    border-radius: $border-radius;
    border: 1px solid $export-forms-border-color;
  }
}

.export-search-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
