.standard-export {
    display: none;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;

    &.active {
        display: inline-flex;
    }

    .column {
        padding-right: 10px;

        .row {
            padding: 10px 10px 10px 0;

            .inline-button {
                display: inline-flex;
                margin-right: 15px;
            }
        }
    }
}
