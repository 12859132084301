.d3-tooltip {
  position: absolute;
  opacity: 0;
  display: block;
  background: #000;
  color: #fff;
  border-radius: 3px;
  z-index: 99;
  padding: 5px 10px;
  transform: translate(-50%, calc(-100% - 10px));

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #000;
    top: 100%;
  }
}
