@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500&display=swap');

@font-face {
    font-family: 'Raleway';
    src: url(../fonts/Raleway-Regular.ttf);
    font-weight: 400;
}
@font-face {
    font-family: 'Raleway';
    src: url(../fonts/Raleway-Medium.ttf);
    font-weight: 600;
}
@font-face {
    font-family: 'Raleway';
    src: url(../fonts/Raleway-Bold.ttf);
    font-weight: 700;
}

* {
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    outline: none;
}

.ReactModalPortal {
    z-index: 1001;
}

.ReactModal__Overlay {
    z-index: 1001;
}

html, body {
    margin: 0;
    padding: 0;
    background-color: #ddd;
}

p, h1, h2, h3 {
    margin: 0;
    padding: 0;
}

::placeholder {
    color: #999999;
    opacity: 1;
}

:-ms-input-placeholder {
    color: #999999;
}

::-ms-input-placeholder {
    color: #999999;
}

*::-webkit-scrollbar-track {
    background-color: transparent;
}

*::-webkit-scrollbar {
    width: 4px;
    background-color: #f5f5f5;
}

*::-webkit-scrollbar-thumb {
    background-color: #555555;
    border: 1px solid #555555;
}
