.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black */
    z-index: 999; /* Lower than modal's z-index */
}


.loadingOverlay {
    position: absolute;
    top: 0;
    left: -500px;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
    color: black;
    font-size: 20px;
}
