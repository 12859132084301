// Assuming Colors are defined somewhere
$badge-background:#000000;
$badge-text: #FFFFFF;
$black: #000000;
$white: #ffffff;

$badge-background-selected: #000000; // replace with your color value
$badge-text-selected: #ffffff; // replace with your color value

$badge-background-not-selected: #eeeeee;
$badge-text-not-selected: #555555;

.parameter-s1-multiselect {
    .value-container {
      display: flex;
      flex-wrap: wrap;
  
      .value-item {
        margin: 5px;
  
        .button {
            height: 32px;
            border-radius: 3px;
            margin: 0 5px 5px 0;
            padding: 0 10px;
            background-color: $badge-background;
            color: $badge-text;

            background-color: $badge-background-not-selected;
            color: $badge-text-not-selected;


            border: none;
            font-size: 13px;
            opacity: 1;
          
            &.selected {
                background-color: $badge-background-selected;
                color: $badge-text-selected;
              }
          
            &.disabled {
              opacity: 0.6;
              cursor: no-drop;
            }
          
            &:hover {
              cursor: pointer;
            }
          
            &:hover.disabled {
              cursor: no-drop;
            }
          }
      }
    }
  }
  