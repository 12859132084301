
.parameter-container {
    display: flex;
    flex-wrap: wrap;
    .parameter-item {
        height: 100%;
        border-radius: 3px;
        display: flex;
        align-items: center;
        min-height: 34px;
        padding: 0 10px;
        background-color: black; // Replace with your actual color variable
        margin: 10px;
    
        &.disabled {
            opacity: 0.6;
    
            &:hover {
                cursor: no-drop;
            }
        }
    
        .label {
            font-size: 13px;
            font-weight: 300;
            color: #FFFFFF;
        }
    
        .title {
            font-size: 13px;
            font-weight: 700;
            margin-right: 3px;
            color: #FFFFFF;
        }
    
        .remove-button {
            margin-left: 10px;
            
            background-repeat: no-repeat;
            border: none;
            width: [button width]; // Replace with your button width
            height: [button height]; // Replace with your button height
    
            &:hover {
                cursor: pointer;
            }
        }
    }
    
}

