.ArticleDetails {
    .Container {
        padding: 20px;
        background-color: white;
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
        border-radius: 5px;
        border: 2px solid black;
        margin-right: auto;
        overflow-y: auto;
        max-height: 62vh;

        // Adjust width based on the labeling prop
        &[labeling='true'] {
            width: calc(100% - 415px);
        }
    }

    .LoaderContainer {
        padding: 25px 0;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: auto;

        // Adjust width based on the labeling prop
        &[labeling='true'] {
            width: calc(100% - 415px);
        }
    }

    .Wrapper {
        overflow-y: hidden;
    }
}
