.labelling-info {
    position: relative;
    max-height: 163px;
    overflow: auto;
    padding: 0px;
    background-color: white;
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    border: 2px solid black;
    margin-right: auto;
    margin-bottom: 10px;
    min-height: 37px;

    .toggle-button {
        position: absolute;
        top: 5px;
        right: 5px;
        cursor: pointer;
        background: transparent;
        border: none;
        font-size: 16px;
        color: #000;
        padding: 4px;
        z-index: 10;
    }

    .info-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 30px;
        color: #000000;
        font-size: 13px;

        th, td {
            border: 1px solid #ddd;
            padding: 8px;
        }

        th:first-child, td:first-child {
            width: 25px;
            text-align: left;
        }

        th:nth-child(2), td:nth-child(2) {
            width: auto;
            text-align: left;
        }

        th:last-child, td:last-child {
            text-align: center;
            width: 35px;
        }

        tr:hover {
            background-color: #f2f2f2;
            cursor: pointer;
        }

        tr.active {
            background-color: #eef; // Highlight color for the active row
        }

        .remove-button {
            background: none;
            border: none;
            cursor: pointer;
            color: inherit;
            font-size: inherit;
        }
    }
}
