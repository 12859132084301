.react-datepicker {
    display: flex;
    border-radius: 5px 0 0 5px;
    border: 1px solid #bbbbbb;
    overflow: hidden;
    border-right: none;
    height: 282px;

    &__month-container {
        border-right: 1px solid #bbbbbb;
    }

    &__day {
        &--in-selecting-range {
            background-color: rgba(#e90010, 0.5);

            &:hover {
                background-color: #e90010;
            }
        }

        &--keyboard-selected {
            background-color: #ffffff;
            color: #000000;
        }

        &--in-range,
        &--selected {
            background-color: #e90010;
            color: #ffffff;

            &:hover {
                background-color: #e90010;
                color: #ffffff;
            }
        }

        &--today:not(&--selected):not(&--in-range) {
            color: #000000;
            background: #eeeeee;
            border: none;
            line-height: 29px;
        }

        &--today {
            border-radius: 5px;
            color: #ffffff;
            border: 2px solid #000000;
            height: 29px;
            line-height: 26px;
        }
        &--outside-month {
            height: unset;
            line-height: 1.7rem;
            border: none;
        }
    }

    &__current-month {
        font-size: 15px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #eeeeee;
        border-bottom: 1px solid #bbbbbb;
    }

    &__day-names {
        height: 35px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__navigation {
        top: 13px;
    }

    &__navigation--years-upcoming {
        top: -4px;
    }

    &__navigation--years-previous {
        top: 4px;
    }

    &__month-container {
        float: none;
    }

    &__header {
        padding-top: 0;
        background-color: #ffffff;
        height: 85px;
        border-top-right-radius: 0;
        border-bottom: 1px solid #bbbbbb;
        border-top-left-radius: 5px !important;

        &__dropdown {
            position: absolute;
            top: 12px;
            right: 34px;
        }
    }

    &__year {
        &-dropdown {
            width: 82px;
            left: -30px;
            top: 18px;
        }
        &-read-view {
            visibility: visible !important;

            &--down-arrow {
                margin: 0;
                top: 0px;
            }

            &--selected-year {
                display: none;
            }
        }
    }
}
