.filter-highlight-tooltip {
    // Basic styling
    background-color: rgba(0, 0, 0, 0.7); // semi-transparent black background
    color: white;
    border-radius: 4px;

    white-space: nowrap;

    // Tooltip positioning and visibility
    position: absolute;
    z-index: 1000; // ensure it's on top of other elements
    visibility: hidden; // hidden by default; can be toggled by JavaScript
    opacity: 0;
    transition: opacity 0.2s;

    line-height: 14px;
    font-size: 14px;
    padding: 16px;

    // Arrow at the bottom of tooltip
    &::after {
        content: '';
        position: absolute;
        top: 100%; // Position arrow at the bottom of tooltip
        left: 50%;
        transform: translateX(-50%);
        border-width: 5px;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
        bottom: -10px; // Move the arrow 10 pixels below the tooltip (adjust this value if necessary)
        top: auto; // Remove the top positioni
    }

    &.showTooltip {
        visibility: visible;
    }
}

.showTooltip {
    visibility: visible;
    opacity: 1;
}

.modal-labelling {
    position: fixed; /* Fixed positioning to keep the modal in place even when scrolling */
    z-index: 10000; /* High z-index to ensure it appears above other elements */
    background-color: white; /* Background color of the modal */
    border: 1px solid #ccc; /* Border for the modal */
    padding: 20px; /* Padding inside the modal */
    height: 150px; /* Height of the modal */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5); /* Shadow for a 3D effect */

    /* Centering the modal vertically and horizontally */
    top: 50%; /* Position the top edge of the modal in the center of the viewport vertically */
    left: 50%; /* Position the left edge of the modal in the center of the viewport horizontally */
    transform: translate(-50%, -50%); /* Shift the modal back by half of its own width and height to center it */

    .modal-content {
        margin: 15px;
    }
}