.S3ParameterSelector {
    display: flex;
    align-items: center;
    justify-content: flex-start; // Align items to the left

    .main-parameter, .parameter-input {
        flex: 1; // This allows each section to take equal space
        margin-right: 10px; // Adds margin to the right of each element
    }

    .submit-button{
        flex: 1;
        // No right margin for the last element
    }

    .main-parameter, .parameter-input  {
        position: relative;
        // Other styles...
    
        .edit-input-button, .clear-input-button {
            position: absolute;
            top: 50%; // Adjust as necessary to align vertically
            right: 10px; // Adjust as necessary for horizontal placement
            transform: translateY(-50%); // To center align vertically
            z-index: 2; // To ensure it's above the AutoComplete component
    
            // Additional styles if needed...
        }
    
        .clear-input-button {
            right: 40px; // Adjust as necessary if you need more space between buttons
        }
    }
}
