.rc-collapse {
  background: transparent;
  border: 0;
  width: 100%;

  > .rc-collapse-item > .rc-collapse-header {
    padding: 0;
    line-height: normal;
    font-size: 16px;
    color: black;

    .arrow {
      border-top: 3px solid transparent;
      border-bottom: 3px solid transparent;
      border-right: 3px solid transparent;
      border-left: 4px solid #000;
      transition: .3s;
      margin-right: 5px;
    }

    &[aria-expanded="true"] .arrow {
      transform: rotate(90deg);
    }
  }

  .rc-collapse-content {
    background: transparent;
    padding: 0 13px;
  }
}
