#leave-feedack {
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 99;
}

#article-content {
    table {
        width: 100%;
    }
}

.tooltip-custom {
    background-color: #c3eac3;
    border: 1px solid #b3d2b3;
    padding: 8px 16px;
    position: fixed;
    top: 13px;
    z-index: 9;
    left: 40%;
    border-radius: 4px;
}

.activity-headline {
    width: 100% !important;
}

.activity-row {
    padding: 12px 15px;
    border-bottom: 3px solid #f0f0f0;
}

.activity-date {
    font-size: 14px;
}

.activity-author {
    font-weight: bold;
    margin-left: 10px;
}

.activity-rating {
    padding: 2px 6px;
    border-radius: 3px;
    color: #fff;
    font-size: 11px;
    margin-left: 10px;

    &.fake {
        background: #e90010;
    }
    &.true {
        background: #00c321;
    }
    &.neutral {
        background: #555555;
    }
}

.selection-highlight {
    background-color: rgb(255, 201, 205);
    color: black; /* Ensuring that text color contrasts well with the highlight */
    padding: 0.1em; /* Adds a little space around the highlighted text */
    margin: 0; /* Avoid extra space around the highlight */
    display: inline; /* Make sure the highlight does not add block-like properties */
    border-radius: 5px; /* Optional: Rounds the corners of the highlight for a smoother look */

    &.active {
        border-style: dotted;
        border-color: #e90010;
        border-width: 2px;
        border-left: none;
        border-right: none;
    }
}

.filter-highlight .selection-highlight, .mark-filter-highlight .selection-highlight {
    color: #e90010 !important;
}


mark.filter-highlight, mark.mark-filter-highlight {
     color: #e90010 !important;
}

.mark-filter-highlight {
    color: #e90010 !important;
}

.filter-highlight, .mark-filter-highlight {
    color: #e90010 !important;
}

.mark {
    font-weight: bold;
    color: #e90010 !important;
    cursor: pointer;
    &.true {
        color: #e90010 !important;
    }
}


.filter-highlight {
    font-weight: bold;
    color: #e90010 !important;
    background-color: white !important;
    cursor: pointer;
    &.true {
        color: #e90010 !important;
    }
}

.filter-highlight-error {
    font-weight: bold;
    color: #eb7a10;
    cursor: pointer;
    &.true {
        color: #eb7a10;
    }
}

.filter-search {
    .container {
        padding: 0px;
        margin-top: 20px;
    }
}

.filter-highlight-tooltip {
    visibility: collapse;
}

.vertical-center {
    align-items: center;
    justify-content: center;
    display: flex;
}

.horizontal-center {
    text-align: center;
}

.bold {
    font-weight: 600;
}

.one-block {
    &:hover {
        background-color: #eeeeee;
    }
}

div[id^='google-visualization-errors'] {
    display: none;
}
.mdl-navigation a {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #414042;
}

#article-content {
    word-break: break-word;

    * {
        float: none !important;
    }

    iframe {
        min-height: 500px;
        width: 560px;
        max-width: 100%;
        margin: auto;
        display: block;
        position: initial !important;
    }

    h4,
    .intro {
        font-size: 24px;
        font-weight: 400;
        line-height: 32px;
        -moz-osx-font-smoothing: grayscale;
        margin-top: 24px;
        margin-bottom: 16px;
    }

    a {
        font-weight: 400;
        position: initial !important;

        &:hover {
            text-decoration: none;
        }
    }

    .mdl-tooltip {
        transform: scale(0);
        transform-origin: top center;
        z-index: 999;
        background: rgba(97, 97, 97, 0.9);
        border-radius: 2px;
        color: rgb(255, 255, 255);
        display: inline-block;
        font-size: 10px;
        font-weight: 500;
        line-height: 14px;
        max-width: 170px;
        width: 170px;
        position: fixed;
        top: -500px;
        left: -500px;
        padding: 8px;
        text-align: center;

        &.showTooltip {
            visibility: visible;
            transform: translate(-50%, -100%);
        }
        &.displayTooltipOverTranslate {
            z-index: 100000;
            visibility: visible;
            transform: translate(-50%, -100%);
        }
    }

    .mdl-tooltip--large {
        line-height: 14px;
        font-size: 14px;
        padding: 16px;
    }

    p {
        margin: 16px 0;
        font-size: inherit;
    }

    img {
        max-width: 630px;
        height: auto;
        margin: auto;
        display: block;

        @media (max-width: 700px) {
            max-width: 100%;
        }
    }

    b,
    strong {
        font-weight: 600;
    }

    ul,
    ol {
        padding-left: 24px;
    }

    .blockquote {
        padding: 16px 0 16px 24px;
        font-size: 18px;
        font-weight: 300;
    }

    figure {
        max-width: 100%;
        margin: 0;
    }

    .mdl-button__ripple-container {
        display: block;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 0;
        overflow: hidden;
    }

    .image-search-button-wrapper {
        float: right;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -46px;

        .search-image-title {
            margin-right: 16px;
            font-size: 16px;
        }
        button:first-of-type {
            margin-right: 8px;
        }
        button {
            background-color: #ffffff;
            mix-blend-mode: normal;
            border: 1px solid #e1e1e1;
            box-sizing: border-box;
            border-radius: 30px;
            padding: 0 7px;
            box-shadow: unset;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            position: relative;

            &:hover {
                .tooltip {
                    display: block;
                }

                img {
                    opacity: 0.77;
                }
            }

            .tooltip {
                display: none;
                position: absolute;
                left: 0;
                transform: translateX(-80%);
                padding: 5px;
                bottom: calc(100% + 3px);
                top: auto;
                border-radius: 3px;
                background-color: #333;
                color: #fff;
                width: 90px;
            }

            img {
                cursor: pointer;
                border-radius: 50%;
                transition: opacity 0.3s;
                max-width: 100%;
            }

            &:last-of-type img {
                transform: scale(1.4);
            }

            .mdl-button {
                background-color: #fff;
            }
        }
    }

    blockquote {
        border-radius: 5px;
        max-width: 100%;
        background-color: #f5f5f5;
        padding: 15px 20px;
        font-style: italic;
    }
}

::-webkit-scrollbar-thumb {
    border-radius: 9px;
}

::-webkit-scrollbar-thumb:hover {
    background-color: #e90111;
    border-color: #e90111;
}

.goog-te-gadget {
    background: #fff;
}

.goog-te-gadget-simple {
    height: 40px;
    border-radius: 5px;
    border: 1px solid #dddddd;
    background-color: #ffffff;
    padding: 0 10px;
    display: inline-flex !important;
    align-items: center;
    width: 150px;

    > span {
        width: 100%;
        display: block;
    }

    img {
        display: none;
    }

    a {
        font-size: 13px;
        color: #000000;
        margin: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            border: none !important;

            &:last-of-type {
                color: transparent !important;
                background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAFCAYAAAB8ZH1oAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAACqADAAQAAAABAAAABQAAAAC5KC8kAAAANElEQVQIHWNgYGBoAOL/BDBIDRg0AElcikFyKKAByENXDBLDChqAojDFIDZe0ACUBWEUAAAHeBjtzdqNQAAAAABJRU5ErkJggg==)
                    no-repeat center/contain;
                width: 10px;
                height: 5px;
                display: inline-block;
                vertical-align: -7px;
                margin-left: auto;
                margin-right: 0;
            }
        }
    }
}

#google_translate_element {
    display: none;
    width: 100%;
    z-index: 999;
    top: 0;
    left: 0;
    border-bottom: 1px solid #555;

    &.show {
        display: block;
    }
}

.goog-te-gadget {
    font-family: 'Raleway' !important;
    text-transform: uppercase;
}
.goog-te-gadget-simple {
    background-color: rgba(255, 255, 255, 0.2) !important;
    border: 1px solid rgba(255, 255, 255, 0.5) !important;
    padding: 8px !important;
    border-radius: 4px !important;
    font-size: 1rem !important;
    line-height: 2rem !important;
    display: inline-block;
    cursor: pointer;
    zoom: 1;
}
.goog-te-menu2 {
    max-width: 100%;
}
.goog-te-menu-value {
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
    padding: 0 5px;
}
.goog-te-menu-value span:nth-child(5) {
    transform: translateX(7px);
    width: 8px !important;
    height: 4px !important;
}
.goog-te-menu-value span:nth-child(3) {
    border: none !important;
}

// ============ HIDE TOP BAR ============
.goog-te-banner-frame.skiptranslate {
    display: none !important;
}
body {
    top: 0 !important;
}

.twitter-tweet {
    margin-left: auto !important;
    margin-right: auto !important;
}

#main.shrink {
    max-height: calc(100vh - 220px) !important;
    height: calc(100vh - 220px) !important;
}

aside.shrink {
    height: calc(100vh - 100px);
}

#labelingContainer.shrink {
    height: calc(100% - 41px);
}
