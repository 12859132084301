.labeling-tabs {
    .labeling-tabs-header {
        display: flex;
        padding: 0;
        margin: 0;
    
        .tab {
            font-size: 13px;
            text-align: left;
            padding: 10px 30px 0px 0px;
            border: none;
            border-bottom: 3px solid transparent;
            background-color: transparent;
            cursor: pointer;
            outline: none;
            transition: border-color 0.3s;
            padding-left: 10px;
    
            &:hover {
                border-bottom-color: #d1cece;
            }
    
            &.active {
                border-bottom-color: #e90010;
            }
        }
    }
    
    .labeling-tabs-content {
        padding: 7px;
        border: 1px solid #ccc;
        border-radius:5px;
    }
}

