.drop-zone {
    border: 2px dotted #888;
    padding: 20px;
    text-align: center;
}

.cloud-icon {
    width: 100px;
    height: 100px;
    margin-bottom: 10px;
}

.select-file-btn {
    cursor: pointer;
    padding: 5px;
    margin-top: 10px;
}