.highlightWarningPopup {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 16px;  // Increased padding
    background-color: black;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    color: white;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;  // Increased font size

    .iconButton, .closeButton {
        background: none;
        border: none;
        color: white;
        cursor: pointer;
        display: flex;
        align-items: center;

        svg {
            width: 24px;  // Adjust icon size as needed
            height: 24px;
        }
    }

    .closeButton {
        margin-left: auto;
    }
}
