.advanced-export {
    display: none;
    max-height: 50vh;
    overflow: auto;

    .export-type-selection,
    .column-selection-group {
        display: inline-flex;
        align-items: center;

        & > *:not(:first-child) {
            padding-left: 10px;
        }
    }

    .export-type-selection {
        padding-bottom: 7px;

        .label {
            width: 66px;
            display: flex;
        }

        .container {
            width: auto;

            .row {
                display: flex;
                padding-top: 5px;

                > * {
                    margin-right: 5px;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .by-single-parameter {
        display: flex;
        padding-top: 5px;
        padding-bottom: 5px;
        .label {
            width: 135px;
            display: flex;
            align-items: center;
        }

        .container {
            width: 318px;
        }
    }

    .column-selection-group {
        justify-content: space-between;
        padding-top: 5px;

        .select-column-button {
            display: inline-flex;
            margin-right: 10px;
        }
    }
}

.advanced-export.active {
    display: block;
}
