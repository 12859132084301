/* HighlightActionsPopup.scss */
.popupContainer {
    position: fixed;
    z-index: 1000;
    padding: 8px;
    background-color: black;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    color: white;
    align-items: center;
    justify-content: space-between;

    .iconButton, .closeButton {
        background: none;
        border: none;
        color: white;
        cursor: pointer;
        display: flex;
        align-items: center;

        svg { /* Assuming your icons are SVG elements */
            width: 16px;
            height: 16px;
        }
    }

    .closeButton {
        margin-left: auto;
        font-size: 12px; /* This will not affect the icon size, only the font size if there's text inside the closeButton */
    }
}
